
import list from "@/components/common/list.vue";

import { defineComponent, ref, onMounted, nextTick } from "vue";
import Loading from "@/components/UI/Loading";
import axios from "@/api/axios";
import Modal from "@/components/UI/Modal";
import Toast from "@/components/UI/Toast";
import dateFormat from "@/utils/dateFormat";
import { useRoute, useRouter } from "vue-router";
import wechatLogin from "@/api/wechatLogin";
import qs from "qs";
import $ from "jquery";
import api from "@/api/axios";
export default defineComponent({
  name: "Intergral",
  components: {
    Loading,
    list
  },
  setup() {
    const intergralData = ref(null);
    const route = useRoute();
    const height = ref("0px");
    const active = ref(0);
    const children = ref([]);
    const leftList = ref([]);
    onMounted(() => {
      height.value =
          Number($(window).height()) -
          Number($("header").height()) -
          Number(135) -
          Number($("footer").height()) + "px";
    });
    function onClick(id: string, sort: number) {

      active.value = Number(sort);
      axios
          .get("/M/User/Extractinter", {
            params: {
              type: id
            },
          })
          .then((res) => {
            if (res.data.success) {
              children.value = res.data.obj.plist;
            } else {
              Toast({
                type: "error",
                title: res.data.msg,
              });
            }
          })
          .catch((err) => {
            console.log("err");
          });

    }

    type Menu = "积分明细" | "兑换记录" | "专属定制";
    const menu: Menu[] = ["积分明细", "兑换记录", "专属定制"];
    const type = route.query.type;
    const currentMenuItem = ref(menu[2]);
    function selectMenu(item: Menu) {
      currentMenuItem.value = item;
    }
    if (type) {
      currentMenuItem.value = menu[Number(type)];
    }
    wechatLogin(route.query.code as string, route.query.state as string).then(
        () => {
          axios
              .get("/M/User/Extractinter")
              .then((res) => {
                if (res.data.success) {
                  intergralData.value = res.data.obj;
                  leftList.value = res.data.obj.listCategory;
                  children.value = res.data.obj.plist;
                } else {
                  Toast({
                    type: "error",
                    title: res.data.msg,
                  });
                }
              })
              .catch((err) => {
                console.log("err");
              });
        }
    );

    const loadOption = {
      text: "加载中...",
      color: "#df0024",
      textColor: "#df0024",
    };
    const router = useRouter();
    function exchange(id: string, needCredits: string) {
      if (Number((intergralData.value as any).jiFen) < Number(needCredits)) {
        Modal({
          type: "none",
          title: "温馨提示",
          content: "您当前积分不够兑换该商品",
          confirmText: "查看积分规则",
          onConfirm: () => {
            router.push({
              name: "IntergralRule",
            });
          },
        });
      } else {
        router.push({
          name: "Exchange",
          query: {
            id,
          },
        });
      }
    }

    return {
      intergralData,
      dateFormat,
      menu,
      selectMenu,
      currentMenuItem,
      loadOption,
      exchange,
      height,
      leftList,
      onClick,
      active,
      children
    };
  },
});
